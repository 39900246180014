import { breakpoints, colors } from 'styles/config';

import Icon from 'components/Shared/Elements/Icon';
import styled from 'styled-components';

const Button = styled.button`
  position: absolute;
  top: 2rem;
  right: 2rem;
  height: auto;
  margin-bottom: 3rem;
  background: none;
  z-index: 200;

  ${breakpoints.tabletPortraitUp} {
    margin-bottom: 2rem;
  }
`;

const CloseButton = ({ hideModal, visible, ...rest }) => {
  if (!visible) {
    return null;
  }

  return (
    <Button onClick={hideModal} {...rest}>
      <Icon
        css={`
          font-size: 1.8rem;
          color: ${colors.silverChalice};
        `}
        name="close"
      />
    </Button>
  );
};

export default CloseButton;
