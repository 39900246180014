import { breakpoints, colors } from 'styles/config';
import styled, { css } from 'styled-components';

export const Container = styled.div.attrs({ id: 'locale-switcher-container' })`
  position: relative;
  height: 100%;
  overflow: visible;
  cursor: pointer;
`;

export const CurrentLanguage = styled.div`
  display: none;

  ${breakpoints.tabletLandscapeUp} {
    display: flex;
    height: 100%;
    padding: 1.5rem 2rem;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
  }
`;

export const Image = styled.img`
  width: 1.8rem;
  height: auto;
  margin-right: 1rem;
`;

export const LanguageName = styled.span`
  font-size: 1.3rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  vertical-align: middle;
  text-decoration: none;
`;

export const CurrentLanguageName = styled(LanguageName)`
  font-size: 1.3rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
`;

export const Languages = styled.ul`
  display: inline-block;
  height: auto;
  width: 100%;

  ${breakpoints.tabletLandscapeUp} {
    display: none;
    position: absolute;
    top: 9rem;
    right: 0;
    background: ${colors.white};
    z-index: 999;
    padding: 0;
    margin-left: 0;
    border: 0.1rem solid ${colors.wildSand};

    ${Container}:hover & {
      display: inline-block;
      width: auto;
    }
  }

  ${breakpoints.desktopUp} {
    right: auto;
    left: 0;
  }
`;

export const Li = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  ${props =>
    props.inactive &&
    css`
      pointer-events: none;
      cursor: default;
      opacity: 0.3;
    `}

  ${breakpoints.tabletLandscapeUp} {
    display: inline-block;
    width: auto;
    padding: 0;
  }
`;

export const Link = styled.a`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 2rem;

  &:hover,
  &:focus {
    background: ${colors.wildSand};
  }

  img {
    margin-right: 0.6rem;
  }

  ${breakpoints.tabletLandscapeUp} {
    width: auto;
    min-width: 13rem;
  }
`;
