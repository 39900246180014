import Icon from 'components/Shared/Elements/Icon';
import { colors } from 'styles/config';
import { memo } from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 60%;
  flex-grow: 1;
  background-color: ${colors.white};
`;

const Input = styled.input`
  height: ${props => (props.layout === 'inline' ? '5rem' : '4rem')};
  padding: 1rem 1.4rem;

  ::placeholder {
    color: ${props => (props.layout === 'inline' ? colors.silver : 'transparent')};
    font-size: 1.3rem;
    font-weight: 400;
  }
`;

export default memo(props => {
  const { icon } = props;

  return (
    <InputContainer>
      <Input {...props} />
      {icon ? <Icon name={icon} /> : null}
    </InputContainer>
  );
});
