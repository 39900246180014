import { breakpoints } from 'styles/config';

import styled from 'styled-components';

const Navigation = styled.nav`
  display: none;
  height: 100%;
  align-items: center;
  flex-grow: 2;
  justify-content: flex-end;
  position: static;
  padding-top: 0;

  ${breakpoints.tabletLandscapeUp} {
    display: flex;
  }
`;

export default Navigation;
