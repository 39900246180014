import styled from 'styled-components';

const PortalContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  transform: translated3d(0, 0, 0);
  transition: opacity 200ms ease-in-out;
  z-index: 2000;
`;

export default PortalContainer;
