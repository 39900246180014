import { colors } from 'styles/config';
import styled from 'styled-components';

const Description = styled.div`
  display: none;
  padding-bottom: 1rem;
  color: ${colors.silverChalice};
  font-size: 1.3rem;
`;

export default Description;
