import { Drawer } from 'antd';

export default ({ isOpen, children, onClose }) => {
  return (
    <Drawer
      bodyStyle={{ padding: '20px 0' }}
      closable={false}
      placement="right"
      title={null}
      visible={isOpen}
      zIndex={2005}
      onClose={onClose}
    >
      {children}
    </Drawer>
  );
};
