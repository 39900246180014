import { breakpoints, colors } from 'styles/config';

import Icon from 'components/Shared/Elements/Icon';
import getConfig from 'next/config';
import styled from 'styled-components';

const { publicRuntimeConfig } = getConfig();

const SocialIcons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  ${breakpoints.desktopUp} {
    width: auto;
    position: absolute;
    right: 0;
    bottom: 3rem;
  }
`;

const SocialLink = styled.a`
  display: flex;
  width: 4rem;
  height: 4rem;
  margin-right: 1.5rem;
  float: left;
  align-items: center;
  justify-content: space-around;
  color: #fff;
`;

const iconCss = `
  width: auto;
  height: auto;
  color: ${colors.white};
  font-size: 4rem;
`;

export default () => (
  <SocialIcons>
    <SocialLink href={publicRuntimeConfig.facebookUrl} target="_blank">
      <Icon css={iconCss} name="facebook-square" />
    </SocialLink>
    <SocialLink href={publicRuntimeConfig.instagramUrl} target="_blank">
      <Icon css={iconCss} name="instagram-square" />
    </SocialLink>
  </SocialIcons>
);
