import { breakpoints, colors } from 'styles/config';

import Icon from 'components/Shared/Elements/Icon';
import SocialIcons from './SocialIcons';
import getConfig from 'next/config';
import { memo } from 'react';
import styled from 'styled-components';

const { publicRuntimeConfig } = getConfig();

const Container = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 2rem;

  ${breakpoints.desktopUp} {
    position: relative;
    flex-basis: auto;
    width: auto;
    flex-grow: 1;
  }
`;

const H3 = styled.h3`
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
  font-weight: 200;
  color: ${colors.yellowGreen};
`;

const Address = styled.div`
  margin-bottom: 4rem;
`;

const textCss = `
  color: ${colors.white};
  font-size: 1.5rem;
  font-weight: 400;
`;

const Text = styled.div`
  ${textCss};
`;

const Title = styled.div`
  margin-bottom: 0.5rem;
  color: ${colors.yellowGreen};
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.5rem;
`;

const iconCss = `
  width: auto;
  margin-right: 1rem;
  color: ${colors.yellowGreen};
  font-size: 1.6rem;
`;

const PhoneContainer = styled.div`
  display: block;
  margin-bottom: ${props => (props.last ? 0 : '3rem')};
`;

const Phone = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const PhoneLink = styled.a`
  ${textCss}
  text-decoration: none;
`;

const Details = ({ t, property }) => {
  if (!property) {
    return null;
  }

  const { name, street, zip, city, managerFirstName, managerLastName, managerPhone } = property;

  const managerName = `${managerFirstName} ${managerLastName}`;

  return (
    <Container>
      <H3>{name}</H3>
      <Address>
        <Text>{street}</Text>
        <Text>
          {zip} {city}
        </Text>
      </Address>
      <PhoneContainer>
        <Title>{t('booking')}</Title>
        <Phone>
          <Icon className={iconCss} name="phone" />
          <PhoneLink href={`tel:${publicRuntimeConfig.bookingPhone}`}>{publicRuntimeConfig.bookingPhone}</PhoneLink>
        </Phone>
      </PhoneContainer>
      <PhoneContainer last>
        <Title>{t('manager')}</Title>
        <Text>{managerName}</Text>
        <Phone>
          <Icon className={iconCss} name="phone" />
          <PhoneLink href={`tel:${managerPhone}`}>{managerPhone}</PhoneLink>
        </Phone>
      </PhoneContainer>
      <SocialIcons />
    </Container>
  );
};

export default memo(Details);
