import * as Styled from './styled';
import * as helpers from '../../../helpers';

import Dotdotdot from 'react-dotdotdot';
import { memo } from 'react';
import { useTranslation } from 'i18n';

const ListItem = ({ apartment, query }) => {
  const { t } = useTranslation(['common']);

  const {
    name,
    thumbnailUrl,
    description,
    minPrice,
    totalPrice,
    minNumAdults,
    maxNumAdults,
    bedrooms,
    area,
    locationSlug,
  } = apartment;

  const urlQuery = Object.keys(query).length ? { ...query, location: locationSlug } : {};

  const url = helpers.getApartmentExternalUrl(apartment, urlQuery);

  return (
    <Styled.Container>
      <Styled.Image name={name} src={thumbnailUrl} url={url} />
      <Styled.Details>
        <Styled.Name name={name} url={url} />
        <Styled.Description>
          <Dotdotdot clamp={3}>{description}</Dotdotdot>
        </Styled.Description>
        <Styled.Attributes area={area} bedrooms={bedrooms} maxNumAdults={maxNumAdults} minNumAdults={minNumAdults} />
        <Styled.PriceButton>
          <Styled.Price minPrice={minPrice} totalPrice={totalPrice} />
          <Styled.Button text={t('bookNow')} url={url} />
        </Styled.PriceButton>
      </Styled.Details>
    </Styled.Container>
  );
};

export default memo(ListItem);
