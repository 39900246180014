import styled from 'styled-components';
import { useTranslation } from 'i18n';

const Container = styled.div`
  padding-bottom: 1rem;
  font-size: 2.4rem;
  font-weight: 600;
`;

const Price = ({ minPrice, totalPrice, className }) => {
  const { t } = useTranslation(['common']);

  return <Container className={className}>{totalPrice || t('common:priceFrom', { price: minPrice })}</Container>;
};

export default Price;
