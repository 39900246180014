import styled from 'styled-components';

const hiddenCss = `
  position: absolute;
  left: -3000%;
  pointer-events: none;
  opacity: 0;
`;

export default styled.label`
  flex-grow: 1;
  flex-wrap: nowrap;
  flex-basis: 44%;

  ${props => (!props.show ? hiddenCss : null)}
`;
