import BaseDescription from '../Shared/Description';
import BaseImage from '../Shared/Image';
import BaseName from '../Shared/Name';
import BasePrice from '../Shared/Price';
import _Attributes from '../Shared/Attributes';
import _ButtonLink from '../Shared/ButtonLink';
import _Container from '../Shared/Container';
import { breakpoints } from 'styles/config';
import styled from 'styled-components';

export const Image = styled(BaseImage)`
  ${breakpoints.tabletLandscapeUp} {
    flex-basis: 32.7rem;
    width: 32.7rem;
    float: left;
  }
`;

export const Name = styled(BaseName)`
  ${breakpoints.tabletLandscapeUp} {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    flex-basis: 100%;
  }
`;

export const Attributes = styled(_Attributes)`
  ${breakpoints.tabletLandscapeUp} {
    justify-content: flex-start;
  }
`;

export const Button = styled(_ButtonLink)`
  height: 4.5rem;
  width: 100%;
  padding: 0 3rem;

  ${breakpoints.tabletLandscapeUp} {
    width: auto;
  }
`;

export const PriceButton = styled.div`
  ${breakpoints.tabletLandscapeUp} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 3rem);
    position: absolute;
    bottom: 0;
  }
`;

export const Price = styled(BasePrice)`
  ${breakpoints.tabletLandscapeUp} {
    width: 50%;
    padding: 0;
  }
`;

export const Details = styled.div`
  ${breakpoints.tabletLandscapeUp} {
    display: block;
    width: calc(100% - 32.8rem);
    height: 100%;
    position: relative;
    padding-left: 3rem;
    float: left;
  }
`;

export const Description = styled(BaseDescription)`
  max-height: 7rem;
  ${breakpoints.tabletLandscapeUp} {
    display: block;
  }
`;

export const Container = styled(_Container)`
  ${breakpoints.tabletLandscapeUp} {
    padding: 2.5rem;
  }
`;
