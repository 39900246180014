import * as actions from './actions';

import { handleActions } from 'redux-actions';
import produce from 'immer';

const initialState = {
  property: {},
  initialDataFetched: false,
};

const reducer = handleActions(
  {
    [actions.setAppData](state, { payload }) {
      return produce(state, newState => {
        newState.property = payload.property;
        newState.initialDataFetched = true;
      });
    },
  },
  initialState
);

export default reducer;
