import URI from 'urijs';

export const getApartmentExternalUrl = (apartment, query = {}) => {
  /* eslint-disable max-len */
  return URI(
    `${process.env.SS24_HOST}/${apartment.language}/${apartment.locationSlug}/${apartment.propertySlug}/${apartment.slug}`
  )
    .query(query)
    .toString();

  /* eslint-enable */
};
