import { Bottom as BottomContainer } from './styles';
import Container from 'components/Shared/Layout/Container';

const Bottom = () => (
  <BottomContainer>
    <Container>© 2017 Sun Seasons 24. All rights reserved.</Container>
  </BottomContainer>
);

export default Bottom;
