import { breakpoints } from 'styles/config';
import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 3rem;
  background: white;
  overflow: hidden;

  ${breakpoints.tabletLandscapeUp} {
    max-width: 80rem;
    width: auto;
    height: auto;
    padding: 3rem 2rem;
  }
`;
