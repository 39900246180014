import { breakpoints, colors } from 'styles/config';

import styled from 'styled-components';

export const Box = styled.div`
  display: ${props => (props.type === 'inline' ? 'flex' : 'block')};
  color: ${colors.white};
  background: ${colors.shark};
  padding: ${props => (props.type === 'inline' ? '1rem' : '2rem')};
  flex-wrap: wrap;

  ${breakpoints.tabletPortraitUp} {
    flex-wrap: ${props => (props.type === 'inline' ? 'nowrap' : 'wrap')};
  }
`;
