import axios from 'axios';
import cleanDeep from 'clean-deep';
import qs from 'qs';

const defaultOptions = {
  baseURL: process.env.API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-Key-Inflection': 'camel',
    'Tenant-UUID': process.env.API_UUID,
    'Tenant-Token': process.env.API_TOKEN,
  },
  paramsSerializer: params =>
    qs.stringify(cleanDeep(params), { arrayFormat: 'brackets', encodeValuesOnly: true, indices: false }),
};

export default axios.create(defaultOptions);
