import ItemContainer from './ItemContainer';
import Label from './Label';
import PropTypes from 'prop-types';
import { memo } from 'react';

const Item = ({ colon, label, children, className, showLabel, fullWidth }) => {
  const colonString = label && colon ? ':' : '';

  return (
    <ItemContainer className={className} fullWidth={fullWidth}>
      <Label show={showLabel}>{`${label}${colonString}`}</Label>
      {children}
    </ItemContainer>
  );
};

Item.propTypes = {
  colon: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Item.defaultProps = {
  colon: true,
  label: '',
};

export default memo(Item);
