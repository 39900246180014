import { colors } from 'styles/config';
import cx from 'classnames';
import styled from 'styled-components';

const Icon = styled.i`
  display: flex;
  min-width: 3.8rem;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  font-size: 1.8rem;
  color: ${colors.limedSpruce};
`;

export default props => <Icon {...props} className={cx([`icon-${props.name}`], props.className)} />;
