import config, { breakpoints, colors } from 'styles/config';
import { darken } from 'polished';
import styled from 'styled-components';

export const containerCss = `
  align-items: center;
`;

export const HeaderSection = styled.header`
  display: inline-block;
  width: 100%;
  background: ${colors.white};
  box-shadow: ${config.boxShadow1};
`;

export const Inner = styled.div`
  display: flex;
  height: 11.6rem;
  align-items: center;
  justify-content: space-between;
`;

export const logoLinkCss = `
  display: flex;
  align-items: center;
`;

export const MenuToggle = styled.button`
  padding: 0.6rem 1.3rem;
  background: ${colors.wildSand};
  font-size: 1.2rem;
  font-weight: 400;
  border: 0.1rem solid ${colors.wildSandDarker};
  line-height: 1;

  ${breakpoints.tabletLandscapeUp} {
    display: none;
  }
`;

export const Image = styled.img`
  display: block;
  position: static;
  max-height: 8.5rem;
`;

const desktopLinkCss = `
  display: flex;
  margin-right: 3rem;
  padding: 0;
`;

const mobileLinkCss = `
  border-bottom: 0.1rem solid ${colors.wildSand};
`;

const accentLinkCss = `
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: ${colors.white};
  background: ${colors.pictonBlue};
  padding: 1.5rem 2rem;

  &:hover {
    color: ${colors.white};
    background: ${darken(0.03, colors.pictonBlue)};
  }

  ${breakpoints.tabletLandscapeUp} {
    padding: 0 2rem;
    height: 4.5rem;
  }
`;

export const A = styled.a`
  display: block;
  width: auto;
  padding: 1.5rem 2rem;
  font-size: 1.3rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.05rem;

  ${props => (props.mobile ? mobileLinkCss : desktopLinkCss)}
  ${props => (props.accent ? accentLinkCss : null)}
  ${props => (props.mobile && props.accent ? 'justify-content: flex-start;' : null)}
`;
