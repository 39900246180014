import { shallowEqual, useSelector } from 'react-redux';

import Bottom from './Bottom';
import Contact from './Contact/index';
import Map from './Map';
import app from 'modules/app';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  display: block;
  min-height: 300px;
  width: 100%;
`;

const Footer = () => {
  const property = useSelector(app.selectors.getProperty, shallowEqual);

  return (
    <FooterContainer>
      <Contact property={property} />
      <Map latitude={property.latitude} longitude={property.longitude} zoom={property.mapZoom} />
      <Bottom />
    </FooterContainer>
  );
};

export default Footer;
