import InputContainer from './InputContainer';
import { colors } from 'styles/config';
import styled from 'styled-components';

const Textarea = styled.textarea`
  height: 15rem;
  padding: 1rem 1.4rem;
  font-size: 1.3rem;
  font-weight: 500;
  color: ${colors.white};

  ::placeholder {
    color: ${colors.white};
    font-size: 1.3rem;
    font-weight: 500;
  }
`;

export default props => (
  <InputContainer css={props.containerCss}>
    <Textarea {...props} />
  </InputContainer>
);
