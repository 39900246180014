import { breakpoints } from 'styles/config';
import styled from 'styled-components';

const Main = styled.div`
  display: block;
  width: 100%;
  min-height: 40rem;

  ${breakpoints.desktopUp} {
    display: inline-block;
    width: auto;
    flex: 2 0 0;
    order: 1;
  }
`;

export default Main;
