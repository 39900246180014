import * as types from './actionTypes';

import apartments from 'modules/apartments';
import api from 'services/api';
import axios from '_lib/axios';
import { createAction } from 'redux-actions';
import normalize from 'json-api-normalizer';

export const setAppData = createAction(types.SET_APP_DATA);

export const loadAppData = req => async dispatch => {
  try {
    const response = await axios({
      method: 'get',
      url: '/api/tenant-data',
      headers: req ? req.headers : undefined,
    });

    const featuredApartments = normalize(response.data.featuredApartments).accommodation;
    const featuredApartmentsIds = Object.keys(featuredApartments);

    dispatch(apartments.actions.setApartments(featuredApartments));
    dispatch(apartments.actions.setFeaturedIds(featuredApartmentsIds));

    dispatch(setAppData(response.data));
  } catch (e) {
    console.log(e);
  }
};
