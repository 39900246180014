import { breakpoints, colors } from 'styles/config';

import styled from 'styled-components';

export const H3 = styled.h3`
  flex-basis: 100%;
  margin: 2.5rem 0;
  color: ${colors.shark};
  font-size: 2.2rem;
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 0.01rem;

  ${breakpoints.tabletPortraitUp} {
    text-align: left;
  }
`;

export const ApartmentList = styled.div`
  display: flex;
  margin-bottom: 6rem;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const inlineSearchBoxCss = `
  margin-bottom: 2rem;

  ${breakpoints.desktopUp} {
    display: none;
  }
`;

export const horizontalSearchBoxCss = `
  display: none;

  ${breakpoints.desktopUp} {
    display: block;
  }
`;
