import _Description from '../Shared/Description';
import { breakpoints } from 'styles/config';
import styled from 'styled-components';

const Description = styled(_Description)`
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 0;

  ${breakpoints.desktopUp} {
    display: block;
  }
`;

const shorten = text => {
  const maxLength = 70;
  let ret = text;
  if (ret.length > maxLength) ret = `${ret.substr(0, maxLength - 3)}...`;
  return ret;
};

export default props => <Description {...props}>{shorten(props.children)}</Description>;
