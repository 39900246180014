import Button from 'components/Shared/Elements/Button';
import Input from './Input';
import Textarea from './Textarea';
import { breakpoints } from 'styles/config';
import { memo } from 'react';
import styled from 'styled-components';

const Form = styled.div`
  display: block;
  margin-bottom: 3rem;
  width: 100%;

  ${breakpoints.tabletPortraitUp} {
    display: flex;
    flex-wrap: wrap;
  }

  ${breakpoints.desktopUp} {
    align-content: flex-start;
    flex-basis: 49.2rem;
    width: auto;
    margin-right: 4rem;
  }
`;

const inputContainerCss = `
  height: 4.8rem;

  ${breakpoints.tabletPortraitUp} {
    flex-basis: calc((100% - 1.2rem) / 2);
    margin-right: 1.2rem;
  }
`;

const textareaContainerCss = `
  ${breakpoints.tabletPortraitUp} {
    flex-basis: 100%;
  }
`;

const lastInputContainerCss = `
  ${inputContainerCss};

  ${breakpoints.tabletPortraitUp} {
    margin-right: 0;
  }
`;

export default memo(({ t }) => (
  <Form>
    <Input containerCss={inputContainerCss} name="name" placeholder={t('contactForm.name')} type="text" />
    <Input containerCss={lastInputContainerCss} name="email" placeholder={t('contactForm.email')} type="text" />
    <Textarea cols="50" containerCss={textareaContainerCss} placeholder={t('contactForm.message')} rows="6" />
    <Button
      css={`
        width: 100%;

        ${breakpoints.tabletPortraitUp} {
          width: auto;
          margin-left: auto;
          padding: 0 3rem;
        }
      `}
    >
      {t('contactForm.send')}
    </Button>
  </Form>
));
