import { Component } from 'react';
import PortalBox from './PortalBox';
import PortalContainer from './PortalContainer';
import ReactDOM from 'react-dom';
import app from 'modules/app';

const ScrollLock = ({ children }) => {
  app.hooks.useLockBodyScroll();

  return <>{children} </>;
};

class Portal extends Component {
  componentDidMount() {
    this.modalTarget = document.createElement('div');
    this.modalTarget.className = 'portal';
    document.body.appendChild(this.modalTarget);
  }

  componentWillUnmount() {
    document.body.removeChild(this.modalTarget);
  }

  render() {
    const { hidePortal, closeButtonVisible, children, isOpen } = this.props;

    if (!isOpen) {
      return null;
    }

    return ReactDOM.createPortal(
      <ScrollLock>
        <PortalContainer onClick={hidePortal}>
          <PortalBox closeButtonVisible={closeButtonVisible}>{children}</PortalBox>
        </PortalContainer>
      </ScrollLock>,
      this.modalTarget
    );
  }
}

export default Portal;
