import { breakpoints, colors } from 'styles/config';

import styled from 'styled-components';

export const Section = styled.section`
  display: block;
  padding: 4rem 0;
  background: ${colors.shark};
  color: ${colors.white};

  ${breakpoints.tabletLandscapeUp} {
    padding: 6rem 0;
  }

  ${breakpoints.desktopUp} {
    padding: 6rem 0 4rem;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${breakpoints.tabletLandscapeUp} {
    justify-content: space-between;
  }

  ${breakpoints.desktopUp} {
    flex-wrap: nowrap;
  }
`;

export const H1 = styled.h1`
  color: ${colors.white};
  margin-bottom: 3rem;
  flex-basis: 100%;
  flex-grow: 0;
  font-size: 4rem;
  font-weight: 200;
  letter-spacing: 0;
  line-height: 4.6rem;

  ${breakpoints.desktopUp} {
    flex-basis: auto;
    flex-grow: 0;
    flex-basis: 26rem;
  }
`;
