import { breakpoints, colors } from 'styles/config';

import { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'i18n';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;

  ${breakpoints.tabletLandscapeUp} {
    display: flex;
  }
`;

const Attribute = styled.div`
  display: flex;
  align-items: center;

  ${breakpoints.tabletLandscapeUp} {
    margin-right: 2rem;
  }
`;

const Icon = styled.i`
  margin-right: 0.7rem;
  color: ${colors.yellowGreen};
  font-size: 2.2rem;
`;

const Text = styled.span`
  color: ${colors.limedSpruce};
  font-size: 1.4rem;
`;

const Attributes = props => {
  const { t } = useTranslation(['common']);
  const { area, bedrooms, className, maxNumAdults, minNumAdults } = props;

  const bedroomsCount = bedrooms ? `${t('apartments:bedrooms', { count: bedrooms })}` : 'Studio';

  return (
    <Container className={className}>
      <Attribute className="attribute">
        <Icon className="icon-bed" />
        <Text>{bedroomsCount}</Text>
      </Attribute>
      <Attribute className="attribute">
        <Icon className="icon-guests" />
        <Text>
          {minNumAdults} + {maxNumAdults - minNumAdults}
        </Text>
      </Attribute>
      <Attribute className="attribute">
        <Icon className="icon-surface" />
        <Text>
          {area} m<sup>2</sup>
        </Text>
      </Attribute>
    </Container>
  );
};

export default memo(Attributes);
