import * as helpers from './helpers';

import { createSelector } from 'reselect';

const getFeaturedIds = state => state.apartments.featuredIds;
const getSearchedIds = state => state.apartments.searchedIds;
export const getIsFetching = state => state.apartments.isFetching;
const getListApartmentsMap = state => state.apartments.byId.list;

const computeApartment = apartment => ({
  ...apartment.attributes,
  thumbnailUrl: apartment.attributes.image || '/static/images/blank.jpg',
  url: helpers.getApartmentExternalUrl(apartment.attributes),
  minPrice: apartment.attributes.minPriceFormatted,
});

export const getFeaturedApartments = createSelector([getListApartmentsMap, getFeaturedIds], (apartmentsMap, ids) => {
  return ids.map(id => computeApartment(apartmentsMap[id]));
});

export const getSearchedApartments = createSelector([getListApartmentsMap, getSearchedIds], (apartmentsMap, ids) => {
  return ids.map(id => computeApartment(apartmentsMap[id]));
});
