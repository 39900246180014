import { H1, Inner, Section } from './styles';

import Container from 'components/Shared/Layout/Container';
import Details from './Details';
import Form from './Form/index';
import { memo } from 'react';
import { useTranslation } from 'i18n';

const Contact = ({ property }) => {
  const { t } = useTranslation(['common']);

  return (
    <Section>
      <Container>
        <Inner>
          <H1>{t('contactForm.anyQuestions')}</H1>
          <Form t={t} />
          <Details property={property} t={t} />
        </Inner>
      </Container>
    </Section>
  );
};

export default memo(Contact);
