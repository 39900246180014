import BaseAttributes from '../Shared/Attributes';
import _ButtonLink from '../Shared/ButtonLink';
import _Container from '../Shared/Container';
import _Image from '../Shared/Image';
import _Name from '../Shared/Name';
import _Price from '../Shared/Price';
import { breakpoints } from 'styles/config';
import styled from 'styled-components';

const stackedCss = `
  flex-wrap: wrap;
  padding-bottom: 0;

  .attribute {
    flex-basis: 100%;
    margin-bottom: 1rem;
  }

  i {
    display: flex;
    width: 2rem;
    justify-content: space-around;
    margin-right: 1.5rem;
    text-align: center;
  }
`;

export const Attributes = styled(BaseAttributes)`
  flex-basis: 100%;
  order: 2;

  ${breakpoints.smallPhoneOnly} {
    ${stackedCss}
  }

  ${breakpoints.tabletPortraitUp} {
    ${stackedCss}
  }

  ${breakpoints.tabletLandscapeUp} {
    flex-wrap: nowrap;

    .attribute {
      flex-basis: auto;
      margin-bottom: 0;
    }
  }

  ${breakpoints.desktopUp} {
    width: 32.7rem;
    flex-basis: 32.7rem;
    order: 1;

    i {
      padding: 0;
    }

    .attribute {
      flex-shrink: 0;
      margin-bottom: 0;
    }
  }
`;

export const ButtonLink = styled(_ButtonLink)`
  height: 4.5rem;
  width: 100%;
  padding: 0 3rem;

  ${breakpoints.desktopUp} {
    width: auto;
  }
`;

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  ${breakpoints.desktopUp} {
    flex-wrap: nowrap;
  }
`;

export const Container = styled(_Container)`
  flex-direction: row;

  ${breakpoints.tabletPortraitUp} {
    padding: 2.5rem 2.5rem 1.5rem;
  }

  @media (min-width: 660px) {
    padding: 2.5rem 2.5rem 1.5rem;
  }

  ${breakpoints.tabletLandscapeUp} {
    padding: 2.5rem 2.5rem 1.5rem;
    flex-basis: calc((100% - 1.5rem) / 2);
    width: calc((100% - 1.5rem) / 2);
  }
`;

export const Details = styled.div`
  flex-direction: row;

  ${breakpoints.desktopUp} {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: calc(100% - 32.8rem);
    height: 21.8rem;
    position: relative;
    padding-left: 2rem;
    float: left;
  }
`;

export const Image = styled(_Image)`
  flex-basis: 100%;

  ${breakpoints.desktopUp} {
    display: block;
    flex-basis: 32.7rem;
    width: 32.7rem;
    float: left;
  }
`;

export const Name = styled(_Name)`
  ${breakpoints.desktopUp} {
    padding: 0;
    flex-direction: column;
  }
`;

export const Price = styled(_Price)`
  height: 100%;
  flex-basis: 100%;
  order: 1;
  padding: 1rem 0 0;
  font-size: 2rem;

  ${breakpoints.desktopUp} {
    flex-basis: auto;
    order: 2;
    padding-left: 2rem;
  }
`;

export const Top = styled.div`
  display: inline-block;
  width: 100%;
  height: auto;
`;
