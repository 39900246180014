import { breakpoints } from 'styles/config';
import styled from 'styled-components';

const ContainerFlex = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 117rem;
  min-height: 100%;
  padding: 0 2rem;
  width: 100%;

  ${breakpoints.desktopUp} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export default ContainerFlex;
