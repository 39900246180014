import { memo } from 'react';
import styled from 'styled-components';

const Image = styled.img`
  display: block;
  position: static;
`;

const ImageLink = ({ src, name, className, url }) => (
  <a className={className} href={url}>
    <Image alt={name} src={src} />
  </a>
);

export default memo(ImageLink);
