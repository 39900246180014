import * as actions from './actions';

import { handleActions } from 'redux-actions';
import merge from 'deepmerge';
import produce from 'immer';

const initialState = {
  byId: {
    list: {},
  },
  featuredIds: [],
  searchedIds: [],
  isFetching: true,
};

const reducer = handleActions(
  {
    [actions.setApartments](state, { payload }) {
      return produce(state, newState => {
        newState.byId.list = merge(state.byId.list, payload, {
          arrayMerge: (_destinationArray, sourceArray) => sourceArray,
        });
      });
    },
    [actions.setFeaturedIds](state, { payload }) {
      return produce(state, newState => {
        newState.featuredIds = payload;
      });
    },
    [actions.setSearchedIds](state, { payload }) {
      return produce(state, newState => {
        newState.searchedIds = payload;
      });
    },
    [actions.setFetching](state, { payload }) {
      return produce(state, newState => {
        newState.isFetching = payload;
      });
    },
  },
  initialState
);

export default reducer;
