import config, { breakpoints, colors } from 'styles/config';

import styled from 'styled-components';

const Container = styled.article`
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  background: ${colors.white};
  box-shadow: ${config.boxShadow1};

  ${breakpoints.tabletPortraitUp} {
    padding: 1rem;
    flex-basis: calc((100% - 1rem) / 2);
    flex-grow: 0;
    flex-shrink: 0;
  }

  @media (min-width: 660px) {
    padding: 1.5rem;
    flex-basis: calc((100% - 1.5rem) / 2);
  }

  ${breakpoints.tabletLandscapeUp} {
    display: inline-block;
    flex-basis: 100%;
    width: 100%;
    position: relative;
  }
`;

export default Container;
