import { breakpoints } from 'styles/config';
import styled from 'styled-components';

const horizontalOnlyCss = `
  ${breakpoints.desktopUp} {
    flex-wrap: wrap;
    flex-basis: 100%;
  }
`;

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  flex-basis: 100%;

  ${breakpoints.tabletPortraitUp} {
    flex-wrap: nowrap;
    flex-basis: calc((100% - 15rem - 1rem) / 3 * 2);
  }

  ${props => (props.layout === 'horizontal' ? horizontalOnlyCss : null)}
`;
