import { colors } from 'styles/config';
import cx from 'classnames';
import styled from 'styled-components';

const Button = styled.a.attrs(props => ({
  className: cx(props.className, 'button'),
}))`
  display: flex;
  height: 5rem;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: ${colors.white};
  background: ${colors.yellowGreen};

  &:hover {
    background: ${colors.yellowGreenDarkened};
  }
`;

export default Button;
