import { colors } from 'styles/config';
import { hexToRGB } from 'utils/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  height: 39rem;
  width: 100%;
`;

export const Pin = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background: ${colors.pictonBlue};
  box-shadow: 0 0 0 1.2rem ${hexToRGB(colors.pictonBlue, 0.3)};
`;
