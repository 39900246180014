/*
 * COLORS
 */

export const colors = {
  dimgray: '#555555',
  limedSpruce: '#3B4549',
  outerSpace: '#2D3539',
  pictonBlue: '#48A2E8',
  shark: '#2B2D2E',
  silver: '#CDCDCD',
  silverChalice: '#A9A9A9',
  sun: '#FBAD17',
  white: '#FFF',
  wildSand: '#F5F5F5',
  wildSandDarker: '#E3E3E3',
  yellowGreen: '#CDDE84',
  yellowGreenDarkened: '#A2B160',
  facebook: '#3B5998',
  twitter: '#4099FF',
};

export const breakpoints = {
  smallPhoneOnly: '@media (max-width: 349px)',
  phoneOnly: '@media (max-width: 499px)',
  phoneLandscapeUp: '@media (min-width: 500px)',
  tabletPortraitUp: '@media (min-width: 600px)',
  tabletLandscapeUp: '@media (min-width: 900px)',
  desktopUp: '@media (min-width: 1200px)',
  bigDesktopUp: '@media (min-width: 1800px)',
};

export default {
  boxShadow1: '0px 0px 61px -13px rgba(0,0,0,0.24)',
};
