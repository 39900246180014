import { Container, CurrentLanguage, CurrentLanguageName, Image, LanguageName, Languages, Li, Link } from './styles';
import React, { useCallback } from 'react';
import { useTranslation } from 'i18n';

const languageData = {
  pl: {
    image: 'static/images/poland_flag.png',
    name: 'Polski',
  },
  en: {
    image: 'static/images/uk_flag.png',
    name: 'English',
  },
  de: {
    image: 'static/images/germany_flag.png',
    name: 'Deutsch',
  },
};

const AVAILABLE_LANGUAGES = ['pl', 'en', 'de'];

const LanguageSwitcher = () => {
  const { i18n } = useTranslation(['common']);
  const currentLanguage = i18n.language;

  const changeLanguage = useCallback(
    newLanguage => event => {
      event.preventDefault();
      i18n.changeLanguage(newLanguage);
    },
    [i18n]
  );

  return (
    <Container>
      <CurrentLanguage>
        <Image alt={currentLanguage} src={languageData[currentLanguage].image} />
        <CurrentLanguageName>{languageData[currentLanguage].name}</CurrentLanguageName>
      </CurrentLanguage>
      <Languages>
        {AVAILABLE_LANGUAGES.map(language => (
          <Li key={language} inactive={language === currentLanguage}>
            <Link href="#" onClick={changeLanguage(language)}>
              <Image alt={language} src={languageData[language].image} />
              <LanguageName>{languageData[language].name}</LanguageName>
            </Link>
          </Li>
        ))}
      </Languages>
    </Container>
  );
};

export default LanguageSwitcher;
