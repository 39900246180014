import CloseButton from './CloseButton';
import PortalBoxContainer from './PortalBoxContainer';

const PortalBox = ({ children, hidePortal, closeButtonVisible }) => (
  <PortalBoxContainer>
    <CloseButton hidePortal={hidePortal} visible={closeButtonVisible} />
    {children}
  </PortalBoxContainer>
);

export default PortalBox;
