import * as types from './actionTypes';

import axios from '_lib/axios';
import { createAction } from 'redux-actions';
import normalize from 'json-api-normalizer';

export const setFetching = createAction(types.SET_FETCHING);
export const setApartments = createAction(types.SET_APARTMENTS);
export const setFeaturedIds = createAction(types.SET_FEATURED_IDS);
export const setSearchedIds = createAction(types.SET_SEARCHED_IDS);

export const loadApartments = (req, query = {}) => async dispatch => {
  dispatch(setFetching(true));

  try {
    const response = await axios({
      method: 'get',
      url: '/api/accommodations',
      params: { page: 1, items: 50, ...query },
      headers: req ? req.headers : undefined,
    });

    let apartments = {};
    let ids = [];

    if (response.data.data.length) {
      const normalizedData = normalize(response.data);

      apartments = normalizedData.accommodation;
      ids = response.data.data.map(accommodation => accommodation.id);
    }

    dispatch(setApartments(apartments));
    dispatch(setSearchedIds(ids));
    dispatch(setFetching(false));
  } catch (e) {
    dispatch(setFetching(false));
    throw e;
  }
};
