import * as Styled from './styled';
import * as helpers from '../../../helpers';

import Description from './Description';
import { useTranslation } from 'i18n';

const FeaturedItem = ({ apartment }) => {
  const { t } = useTranslation(['common', 'apartments']);
  const image = apartment.thumbnailUrl;

  const { name, description, minPrice, totalPrice, minNumAdults, maxNumAdults, bedrooms, area } = apartment;

  const url = helpers.getApartmentExternalUrl(apartment);

  return (
    <Styled.Container>
      <Styled.Top>
        <Styled.Image name={name} src={image} url={url} />
        <Styled.Details>
          <Styled.Name name={name} url={url} featured />
          <Styled.ButtonLink text={t('bookNow')} url={url} />
          <Description featured>{description}</Description>
        </Styled.Details>
      </Styled.Top>
      <Styled.Bottom>
        <Styled.Attributes area={area} bedrooms={bedrooms} maxNumAdults={maxNumAdults} minNumAdults={minNumAdults} />
        <Styled.Price minPrice={minPrice} totalPrice={totalPrice} />
      </Styled.Bottom>
    </Styled.Container>
  );
};

export default FeaturedItem;
