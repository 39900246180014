import { Trans } from 'i18n';
import styled from 'styled-components';

const Prefix = styled.div`
  font-size: 2.3rem;
  font-weight: 200;
`;

const AccommodationName = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
  word-break: break-word;
`;

const A = styled.a`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  padding: 2rem 0 0.8rem;
  line-height: ${props => (props.featured ? 1.2 : 1)};

  > *:first-child {
    margin-right: 0.8rem;
  }
`;

const Name = ({ name, url, className, featured }) => (
  <A className={className} featured={featured} href={url}>
    <Trans
      components={[<AccommodationName key="name">Some</AccommodationName>, <Prefix key="prefix">Apartment</Prefix>]}
      defaults="<0>{{name}}</0><1>Apartment</1>"
      i18nKey="apartmentNameFormatted"
      values={{ name: name }}
    />
  </A>
);

export default Name;
