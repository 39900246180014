import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { pull } from 'lodash';
import { useTranslation } from 'i18n';

export const useLockBodyScroll = () => {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden';
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, []); // Empty array ensures effect is only run on mount and unmount
};

export const useLanguage = () => {
  const AVAILABLE_LANGUAGES = ['pl', 'en', 'de'];

  const {
    i18n: { language },
  } = useTranslation(['common']);

  const inactiveLanguages = useMemo(() => pull(AVAILABLE_LANGUAGES, language), [language]);

  return [language, inactiveLanguages];
};
