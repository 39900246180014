import { colors } from 'styles/config';
import styled from 'styled-components';

export default styled.div`
  display: flex;
  margin-bottom: 1.2rem;
  flex-basis: 100%;
  flex-grow: 0;
  align-items: center;
  background-color: none;
  border: 0.1rem solid ${colors.dimgray};
`;
