import { A, HeaderSection, Image, Inner, MenuToggle, containerCss, logoLinkCss } from './styles';
import { Link, useTranslation } from 'i18n';
import { memo, useCallback, useState } from 'react';
import Container from 'components/Shared/Layout/Container';
import LanguageSwitcher from './LanguageSwitcher';
import MobileNavigation from './MobileNavigation';
import NProgress from 'nprogress';
import Navigation from './Navigation';
import Router from 'next/router';

Router.onRouteChangeStart = () => NProgress.start();
Router.onRouteChangeComplete = () => NProgress.done();
Router.onRouteChangeError = () => NProgress.done();

const Header = () => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const { t } = useTranslation(['common']);

  const toggleSideMenu = useCallback(() => {
    setIsSideMenuOpen(!isSideMenuOpen);
  }, [setIsSideMenuOpen, isSideMenuOpen]);

  return (
    <HeaderSection>
      <Container css={containerCss}>
        <Inner>
          <a css={logoLinkCss} href="/">
            <Image alt="Logo" src="/static/images/logo.png" />
          </a>
          <Navigation>
            <Link as="/" href="/index" passHref>
              <A>{t('navigation.home')}</A>
            </Link>
            <Link as="/rezerwacja" href="/rezerwacja" passHref>
              <A>{t('navigation.apartments')}</A>
            </Link>
            <Link as="/rezerwacja" href="/rezerwacja" passHref>
              <A accent>{t('navigation.bookOnline')}</A>
            </Link>
            <LanguageSwitcher />
          </Navigation>
          <MobileNavigation isOpen={isSideMenuOpen} onClose={toggleSideMenu}>
            <Link as="/" href="/index" passHref>
              <A mobile>{t('navigation.home')}</A>
            </Link>
            <Link as="/rezerwacja" href="/rezerwacja" passHref>
              <A mobile>{t('navigation.apartments')}</A>
            </Link>
            <Link as="/rezerwacja" href="/rezerwacja" passHref>
              <A accent mobile>
                {t('navigation.bookOnline')}
              </A>
            </Link>
            <LanguageSwitcher />
          </MobileNavigation>
          <MenuToggle onClick={toggleSideMenu}>Menu</MenuToggle>
        </Inner>
      </Container>
    </HeaderSection>
  );
};

export default memo(Header);
