import { Container, Pin } from './styles';
import GoogleMapReact from 'google-map-react';
import { useTranslation } from 'i18n';

const Map = props => {
  const {
    i18n: { language },
  } = useTranslation(['common']);

  const latitude = Number(props.latitude);
  const longitude = Number(props.longitude);
  const defaultZoom = Number(props.zoom);

  return (
    <Container>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyArknr3I-RwZtUYmXT_pMZfSmEyd1zy1DM',
          language,
        }}
        defaultCenter={{ lat: latitude, lng: longitude }}
        defaultZoom={defaultZoom}
        language={language}
        options={{ scrollwheel: false, scaleControl: false }}
      >
        <Pin lat={latitude} lng={longitude} />
      </GoogleMapReact>
    </Container>
  );
};

export default Map;
