import {
  DATE_FORMAT,
  END_DATE,
  END_DATE_INPUT_NAME,
  HORIZONTAL_ORIENTATION,
  START_DATE,
  START_DATE_INPUT_NAME,
  VERTICAL_ORIENTATION,
} from './constants';
import { DayPickerRangeController, toMomentObject } from 'react-dates';
import { breakpoints, colors } from 'styles/config';
import { isNull, noop, omit, omitBy } from 'lodash';

import { Component } from 'react';
import DateRangePickerContainer from './DateRangePickerContainer';
import Form from 'components/Shared/Form/index';
import Icon from 'components/Shared/Elements/Icon';
import Portal from './Portal';
import moment from 'moment';
import phrases from './phrases';

const { Input } = Form;

class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    const { language } = props;

    const initialStartDate = toMomentObject(props.initialStartDate, DATE_FORMAT);
    const initialEndDate = toMomentObject(props.initialEndDate, DATE_FORMAT);

    moment.locale(language);

    this.state = {
      isOpen: false,
      focusedInput: initialStartDate ? START_DATE : null,
      startDate: initialStartDate,
      endDate: initialEndDate,
    };
  }

  setOnInputChangeHandler = inputName => value => {
    this.setState({ [inputName]: value }, () => {
      const formInputName = inputName === START_DATE ? START_DATE_INPUT_NAME : END_DATE_INPUT_NAME;
      this.props.change(formInputName, value);
    });
  };

  handleOnDatesChange = dates => {
    const { startDate, endDate } = dates;
    const { change } = this.props;

    this.setState({ startDate, endDate }, () => {
      change(START_DATE_INPUT_NAME, startDate && startDate.format(DATE_FORMAT));
      change(END_DATE_INPUT_NAME, endDate && endDate.format(DATE_FORMAT));

      if (this.state.startDate && this.state.endDate) {
        this.setState({ isOpen: false });
      }
    });
  };

  handleOnFocusChange = focusedInput => {
    const inputToSet = !focusedInput ? START_DATE : focusedInput;
    this.setState({ focusedInput: inputToSet });
  };

  handleOnInputFocus = event => {
    event.stopPropagation();
    event.target.blur();
    let inputName;

    switch (event.target.name) {
      case START_DATE_INPUT_NAME:
        inputName = START_DATE;
        break;
      case END_DATE_INPUT_NAME:
        inputName = END_DATE;
        break;
      default:
        inputName = END_DATE;
    }

    this.setState({ focusedInput: inputName, isOpen: true });
  };

  hidePortal = event => {
    event.stopPropagation();
    this.setState({ isOpen: false, focusedInput: null });
  };

  render() {
    const { focusedInput, startDate, endDate, isOpen } = this.state;
    const { layout, language, showLabels } = this.props;
    const arrivalLabel = 'Przyjazd';
    const departureLabel = 'Wyjazd';

    const FormItemCss = `
      position: relative;
      width: ${layout === 'inline' ? '100%' : 'auto'};
      margin-bottom: 1rem;
      flex-grow: ${layout === 'inline' ? '1' : '0'};

      ${breakpoints.tabletPortraitUp} {
        margin-right: ${layout === 'inline' ? '1rem' : '0'};
        margin-bottom: ${layout === 'inline' ? '0' : '1rem'};
        width: ${layout === 'inline' ? '100%' : 'auto'};
      }
    `;

    const props = omit(this.props, [
      'change',
      'displayFormat',
      'endDatePlaceholderText',
      'initialEndDate',
      'initialStartDate',
      'language',
      'layout',
      'showLabels',
      'startDatePlaceholderText',
    ]);

    const isDatePickerHorizontal =
      typeof window !== 'undefined' ? window.matchMedia('(min-width: 900px)').matches : true;

    const startDateValue = startDate ? startDate.format(DATE_FORMAT) : '';
    const endDateValue = endDate ? endDate.format(DATE_FORMAT) : '';

    const orientationRelatedProps = omitBy(
      {
        orientation: isDatePickerHorizontal ? HORIZONTAL_ORIENTATION : VERTICAL_ORIENTATION,
        navNext: isDatePickerHorizontal ? (
          <Icon
            css={`
              color: ${colors.white};
            `}
            name="arrow-right"
          />
        ) : null,
        navPrev: isDatePickerHorizontal ? (
          <Icon
            css={`
              color: ${colors.white};
            `}
            name="arrow-left"
          />
        ) : null,
        daySize: isDatePickerHorizontal ? 50 : null,
      },
      isNull
    );

    const isOutsideRange = day => day.isBefore(moment().subtract(1, 'days'));

    return (
      <DateRangePickerContainer layout={layout}>
        <Form.Item css={FormItemCss} fullWidth={layout === 'inline'} label={arrivalLabel} showLabel={showLabels}>
          <Input
            css={`
              padding-right: 0 !important;
            `}
            icon="from"
            layout={layout}
            name={START_DATE_INPUT_NAME}
            placeholder={arrivalLabel}
            type="text"
            value={startDateValue}
            readOnly
            required
            onChange={this.setOnInputChangeHandler(START_DATE)}
            onFocus={this.handleOnInputFocus}
          />
        </Form.Item>
        <Form.Item css={FormItemCss} fullWidth={layout === 'inline'} label={departureLabel} showLabel={showLabels}>
          <Input
            css={`
              padding-right: 0 !important;
            `}
            icon="to"
            layout={layout}
            name={END_DATE_INPUT_NAME}
            placeholder={departureLabel}
            type="text"
            value={endDateValue}
            readOnly
            required
            onChange={this.setOnInputChangeHandler(END_DATE)}
            onFocus={this.handleOnInputFocus}
          />
        </Form.Item>
        <Portal closeButtonVisible={!isDatePickerHorizontal} hidePortal={this.hidePortal} isOpen={isOpen}>
          <DayPickerRangeController
            endDate={endDate || null}
            focusedInput={focusedInput}
            isOutsideRange={isOutsideRange}
            phrases={phrases[language]}
            startDate={startDate || null}
            withPortal={!isDatePickerHorizontal}
            isFocused
            onDatesChange={this.handleOnDatesChange}
            onFocusChange={this.handleOnFocusChange}
            {...orientationRelatedProps}
            {...props}
          />
        </Portal>
      </DateRangePickerContainer>
    );
  }
}

DateRangePicker.defaultProps = {
  change: noop,
  displayFormat: DATE_FORMAT,
  monthFormat: 'MMMM YYYY',
  numberOfMonths: 2,
  showLabels: false,
};

export default DateRangePicker;
