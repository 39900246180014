import * as Styled from './styled';

import React, { useCallback } from 'react';
import { Router, useTranslation } from 'i18n';
import { shallowEqual, useSelector } from 'react-redux';

import Apartment from '../Apartment';
import ContainerFlex from 'components/Shared/Layout/ContainerFlex';
import Helmet from 'react-helmet';
import Layout from 'components/Layout';
import Loading from 'react-loading';
import Main from 'components/Shared/Layout/Main';
import SearchBox from 'components/Shared/SearchBox';
import Side from 'components/Shared/Layout/Side';
import apartments from 'modules/apartments';
import app from 'modules/app';
import { colors } from 'styles/config';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const Apartments = ({ query }) => {
  const { t } = useTranslation(['common', 'apartments']);
  const [language, inactiveLanguages] = app.hooks.useLanguage();

  const handleSubmit = useCallback((values, form) => {
    Router.push({
      pathname: '/rezerwacja',
      query: values,
    });

    form.setSubmitting(false);
  }, []);

  const items = useSelector(apartments.selectors.getSearchedApartments, shallowEqual);
  const isFetching = useSelector(apartments.selectors.getIsFetching, shallowEqual);

  return (
    <>
      <Helmet>
        <html lang={language} />

        <link href={`${publicRuntimeConfig.host}/${language}/rezerwacja`} rel="canonical" />
        {inactiveLanguages.map(inactiveLanguage => (
          <link
            key={inactiveLanguage}
            href={`${publicRuntimeConfig.host}/${inactiveLanguage}/rezerwacja`}
            hrefLang={inactiveLanguage}
            rel="alternate"
          />
        ))}
      </Helmet>
      <Layout>
        <ContainerFlex>
          <Styled.H3>{t('common:navigation.apartments')}</Styled.H3>
          <Side>
            <SearchBox
              key={JSON.stringify(query)}
              css={Styled.horizontalSearchBoxCss}
              initialValues={query}
              type="horizontal"
              onSubmit={handleSubmit}
            />
            <SearchBox css={Styled.inlineSearchBoxCss} initialValues={query} type="inline" onSubmit={handleSubmit} />
          </Side>
          <Main>
            {isFetching ? (
              <Loading color={colors.yellowGreen} delay={0} height={35} type="spin" width={35} />
            ) : (
              <Styled.ApartmentList>
                {items.map(apartment => (
                  <Apartment.ListItem key={apartment.id} apartment={apartment} language={language} query={query} />
                ))}
              </Styled.ApartmentList>
            )}
          </Main>
        </ContainerFlex>
      </Layout>
    </>
  );
};

export default Apartments;
