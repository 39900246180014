import { breakpoints } from 'styles/config';
import styled from 'styled-components';

const Side = styled.div`
  display: block;
  width: 100%;

  ${breakpoints.desktopUp} {
    display: inline-block;
    width: 28rem;
    flex-basis: 28rem;
    order: 2;
    margin-left: 1.5rem;
  }
`;

export default Side;
