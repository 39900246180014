import axios from 'axios';

const defaultOptions = {
  withCredentials: true,
  baseURL: process.env.HOST,
  headers: {
    'Content-Type': 'application/json',
  },
};

export default axios.create(defaultOptions);
