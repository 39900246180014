import * as Styled from './styled';

import { Field, Formik } from 'formik';
import { memo, useCallback } from 'react';

import Button from 'components/Shared/Elements/Button';
import DateRangePicker from 'components/Shared/Elements/DateRangePicker';
import Form from 'components/Shared/Form/index';
import PropTypes from 'prop-types';
import { breakpoints } from 'styles/config';
import { useTranslation } from 'i18n';

const { Select } = Form;

const SearchBox = ({ type, className, onSubmit, initialValues }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['common']);

  const showLabel = type === 'horizontal';

  const handleSubmit = useCallback(
    (values, form) => {
      onSubmit(values, form);
    },
    [onSubmit]
  );

  const handleDatesChange = useCallback(
    form => (inputName, value) => {
      form.setFieldValue(inputName, value);
    },
    []
  );

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {props => (
        <form
          css={`
            margin-bottom: 0;
          `}
          onSubmit={props.handleSubmit}
        >
          <Styled.Box className={className} type={type}>
            <DateRangePicker
              change={handleDatesChange(props)}
              endDatePlaceholderText={t('searchBox.labels.departure')}
              initialEndDate={props.values.departure}
              initialStartDate={props.values.arrival}
              language={language}
              layout={type}
              showLabels={showLabel}
              startDatePlaceholderText={t('searchBox.labels.arrival')}
            />
            <Form.Item
              css={`
                width: ${type === 'inline' ? '100%' : 'auto'};
                margin-bottom: 1rem;
                flex-grow: ${type === 'inline' ? '1' : '0'};

                ${breakpoints.tabletPortraitUp} {
                  margin-right: ${type === 'inline' ? '1rem' : '0'};
                  margin-bottom: ${type === 'inline' ? '0' : '1rem'};
                  width: ${type === 'inline' ? 'calc((100% - 15rem - 1rem) / 3)' : 'auto'};
                }
              `}
              fullWidth={type === 'inline'}
              label={t('searchBox.labels.guests')}
              showLabel={showLabel}
              type={type}
            >
              <Field name="guests">
                {fieldProps => (
                  <Select
                    {...fieldProps.field}
                    icon="guests"
                    layout={type}
                    placeholder={t('searchBox.labels.guests')}
                    required
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(count => (
                      <option key={count} value={count}>
                        {t('guestCount', { count: count })}
                      </option>
                    ))}
                  </Select>
                )}
              </Field>
            </Form.Item>
            <Form.Item
              css={`
                width: 100%;
                margin-top: ${type === 'inline' ? '0' : '2rem'};
                margin-bottom: 0;

                ${breakpoints.tabletPortraitUp} {
                  width: auto;
                }
              `}
              showLabel={false}
              fullWidth
            >
              <Button
                as="button"
                css={`
                  width: 100%;
                  margin-bottom: 0;

                  ${breakpoints.tabletPortraitUp} {
                    width: ${type === 'inline' ? '15rem' : '100%'};
                  }
                `}
                disabled={props.isSubmitting}
                type="submit"
              >
                {t('search')}
              </Button>
            </Form.Item>
          </Styled.Box>
        </form>
      )}
    </Formik>
  );
};

SearchBox.propTypes = {
  type: PropTypes.oneOf(['horizontal', 'inline']),
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

SearchBox.defaultProps = {
  type: 'horizontal',
  initialValues: { arrival: undefined, departure: undefined, guests: 2 },
};

export default memo(SearchBox);
